import React from 'react'
// import { Link, useStaticQuery, graphql } from "gatsby"

import Footer from './footer'
import Navigation from './navigation'

export default ({ className, dark, children, hidefooter=false }) => {
  return (
    <React.Fragment>
      <a className="sr-only sr-only-focusable" href="#content">
        Skip to main content
      </a>
      <main
        className={`site-wrapper min-vh-100 ${dark && 'bg-dark'} ${className}`}
        id="content"
      >
        <Navigation />
        {children}
        {hidefooter===false && <Footer />}
      </main>
    </React.Fragment>
  )
}

import React from "react";
import Img from "gatsby-image";

export default function Image({ src, alt, className }) {
  // return <div>{JSON.stringify(src.childImageSharp.fluid)}</div>
  if(typeof src  !== 'object'){
    return <img src={src} className={`${className} no-gatsby`} alt={alt} />;
  }
  else if (src.childImageSharp === null) {
    return <img src={"/"+src.relativePath} className={className+ " w-100 img-fluid"} alt={alt} />;
  } else {
    return (
      <Img fluid={src.childImageSharp.fluid} className={className} alt={alt} />
    );
  }
}

import React from "react";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../useSiteMetadata";
export default ({ pageTitle, pageDescription, url, isBlogPost, pageImage }) => {
  var { siteTitle, siteUrl, siteDescription, fbadminapp, siteImage } = useSiteMetadata();

  if (!pageDescription) {
    pageDescription = siteDescription;
  }
  if (!pageTitle) {
    pageTitle = siteTitle;
  } else {
    pageTitle = pageTitle + " | " + siteTitle;
  }

  if (!pageImage) {
    pageImage = siteImage;
  }

  return (
    <Helmet>
      <html lang="en" />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="image" content={siteUrl + pageImage} />
      <link rel="canonical" href={siteUrl + url} />
      

      {/* OpenGraph tags */}
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:url" content={siteUrl + url} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:pageDescription" content={pageDescription} />
      <meta property="og:image" content={siteUrl + '/' + pageImage} />
      {fbadminapp && <meta property="fb:app_id" content={fbadminapp} />}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteTitle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={siteUrl + '/' + pageImage} />
    </Helmet>
  );
};

import React, { useState } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Burger from '@animated-burgers/burger-arrow'
// don't forget the styles
import '@animated-burgers/burger-arrow/dist/styles.css'

export default function () {
  const [open, setOpen] = useState(false)

  return (
    <nav
      className={`pt-3 pt-lg-4 w-100 text-right ${open && 'open'}`}
      role="navigation"
    >
      <div id="navhead" className="container-fluid">
        <div className="row">
          <div className="col-9 col-md-10 col-lg-10 text-left text-lg-right">
            <AniLink cover bg="#000" className="navbar-brand inner-link" to="/">
              <span>James</span> <span>Dodd</span>
            </AniLink>
          </div>
          <div className="col-3 col-md-2 col-lg-1 relative text-right">
            <Burger
              direction="right"
              isOpen={open}
              onClick={() => setOpen(!open)}
              id="navbutton"
              type="button"
              aria-label="Menu"
              className={`rarr navbar-toggle collapsed text-dark`}
            />
          </div>
        </div>
      </div>
      <div id="navbar" className={`row ${open && 'show'}`}>
        <div className="blur"></div>
        <div className="title">James Dodd Menu</div>
        <div className="menuitems">
          <div>
            <div id="mainmenu" className="text-uppercase text-bold">
              <ul id="menu-main-menu" className="nav navbar-nav">
                <li className="menu-item">
                  <AniLink
                    cover
                    bg="#000"
                    className="inner-link"
                    title="Home"
                    to="/"
                  >
                    Home
                  </AniLink>
                </li>
                <li className="menu-item">
                  <AniLink
                    cover
                    bg="#000"
                    className="inner-link"
                    title="About"
                    to="/about/"
                  >
                    About
                  </AniLink>
                </li>
                <li className="menu-item">
                  <AniLink
                    cover
                    bg="#000"
                    className="inner-link"
                    title="Contact"
                    to="/contact/"
                  >
                    Contact
                  </AniLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 worklistsection">
            <h2>Work</h2>
            <div className="projectlist">
              
              <div className="project">
                <AniLink cover bg="#000" to="/work/they-said/">
                  They said.
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/last-goodbye/">
                  Last Goodbye.
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/sheffield/">
                  Sheffield. Home.
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/the-walkers/">
                  The Walkers
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/SMS/">
                  Sunday Morning Sales
                </AniLink>
              </div>
              
              <div className="project">
                <AniLink cover bg="#000" to="/work/olympic-dreams/">
                  Olympic Dreams
                </AniLink>
              </div>



              <div className="project">
                <AniLink cover bg="#000" to="/work/deadphotographers/">
                  Deadphotographers
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/feasted/">
                  Feasted
                </AniLink>
              </div>
              <div className="project">
                <AniLink cover bg="#000" to="/work/portfolio/">
                  Commercial + Other Work
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
